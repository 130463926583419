/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import PageHeader from "../components/elements/PageHeader/PageHeader";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    blockquote: "blockquote",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(PageHeader, {
    title: "お問い合わせ"
  }), "\n", React.createElement(_components.h3, null, "お問い合わせありがとうございます。"), "\n", React.createElement(_components.p, null, "進路相談・学生生活・学費等についてお問い合わせのある方は、下記電話番号にお電話ください。"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement("a", {
    href: "tel:03-3365-6141"
  }, "03-3365-6141")), "\n月曜日～金曜日 9:00 ～ 17:00\n※学校休館日は除く"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
